import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Hidden, Menu, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import DreamscapeBanner from '../../components/ds-banner/DreamscapeBanner';
import RegisterSelection from './RegisterSelection';
import RegisterForm from './RegisterForm';
import { RegistrationUserTypes } from '../../types/registration/registration-user-types';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router';
import useQueryParams from '../../hooks/UseQueryParams';
import _ from 'lodash';
import mixpanel from '../../utils/mixpanel';
import { getRegistrationFlowValue } from '../../utils/registrations/getRegistrationFlowValue';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorage';

const Register: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQueryParams();
  let renderComponent;
  const [userType, setUserType] = useState<RegistrationUserTypes>('');
  const featureFlag = useSelector((state: any) => state.featureFlag);
  const [
    shouldUseNewRegistrationFlow,
    setShouldUseNewRegistrationFlow
  ] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('/educator')) {
      if (
        featureFlag.newRegistrationFlow &&
        !getLocalStorage('onboardingStartedFired')
      ) {
        const registrationFlowValue = getRegistrationFlowValue();
        setLocalStorage('newRegistrationFlow', registrationFlowValue);
      }
    } else if (location.pathname.includes('/school')) {
      window.location.href =
        'https://share.hsforms.com/1HQI9VAXGSMmI54cscixMwAqjr67';
    }
  }, [location.pathname, featureFlag.newRegistrationFlow]);

  useEffect(() => {
    if (featureFlag.newRegistrationFlow) {
      setShouldUseNewRegistrationFlow(true);
    }
  }, [featureFlag.newRegistrationFlow]);

  useEffect(() => {
    switch (userType) {
      case 'Parent':
        dispatch(push('/register/parent' + location.search));
        break;
      case 'Teacher':
        dispatch(push('/register/educator'));
        break;
      case 'School Admin':
        dispatch(push('/register/school'));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  useEffect(() => {
    switch (location.pathname) {
      case '/register/parent':
        setUserType('Parent');
        break;
      case '/register/educator':
        setUserType('Teacher');
        break;
      case '/register/school':
        setUserType('School Admin');
        break;
      default:
        setUserType('');
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname.includes('/educator') &&
      featureFlag.newRegistrationFlow
    ) {
      if (!getLocalStorage('onboardingStartedFired')) {
        mixpanel.track('Onboarding Started', {
          registrationFlow: getLocalStorage('newRegistrationFlow')
        });
        setLocalStorage('onboardingStartedFired', 'true');
      }
      mixpanel.track('Onboarding - Registration Screen', {
        registrationFlow: getLocalStorage('newRegistrationFlow')
      });
    }
  }, [location.pathname, featureFlag.newRegistrationFlow]);

  const isMembershipFlow = Boolean(query.isMembershipFlow);
  renderComponent = (
    <RegisterForm
      defaultEmail={query.email}
      isMembershipFlow={isMembershipFlow}
      userType={userType}
      shouldUseNewRegistrationFlow={shouldUseNewRegistrationFlow}
    />
  );
  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <DreamscapeBanner title="" />
      </Grid>
      <Grid item xs={12}>
        <RegisterSelection
          showOthers={!isMembershipFlow}
          selectedUserType={userType}
          handleSelectUserType={setUserType}
          shouldUseNewRegistrationFlow={shouldUseNewRegistrationFlow}
        />
      </Grid>
      <Grid item xs={12}>
        {renderComponent}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.userData,
  router: state.router
});

export default connect(mapStateToProps, null)(Register);
